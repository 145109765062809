import React from 'react';
import Session from 'services/Session';
import AppLayout from 'layouts/AppLayout';
import Spinner from 'components/Spinner';
import {RouterHistory} from 'services/History';
import LazyLayout from 'components/LazyLayout';
import {Router, Switch, Route, Redirect} from 'react-router-dom';

export default class extends React.Component {

  constructor () {
    super();
    Session.onChange(() => {
      this.forceUpdate();
    });
  }

  unauthed = (
    <Router history={RouterHistory}>
      <AppLayout>
        <Switch>
          <Route exact path="/login" component={LazyLayout('LoginLayout')}/>
          <Redirect to="/login"/>
        </Switch>
      </AppLayout>
    </Router>
  );

  authed = (
    <Router history={RouterHistory}>
      <AppLayout>
        <Switch>
          <Route path="/home" component={LazyLayout('HomeLayout')}/>
          <Route path="/logout" component={LazyLayout('LogoutLayout')}/>
          <Redirect to="/home"/>
        </Switch>
      </AppLayout>
    </Router>
  );

  render() {
    if (!Session.isReady()) {
      return <Spinner/>
    }
    return (!Session.isLoggedIn())
      ? this.unauthed
      : this.authed
  }
}
