import React from 'react';
import {Layout, Row, Col} from 'antd';
import Session from 'services/Session';
import Header from 'components/layout/Header';
import Footer from 'components/layout/Footer';

export default ({children}) => {

  let header;

  if (Session.isLoggedIn()) {
    header = <Header/>;
  }

  return (
    <Row type="flex">
      <Col span={24}>
        <Layout className="layout">
          {header}
          <Layout.Content style={{ padding: '50px 50px 0' }}>
            {children}
          </Layout.Content>
          <Footer/>
        </Layout>
      </Col>
    </Row>
  )
}
