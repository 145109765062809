import db from './Database';

class Settings {

  collection = db.collection('settings');

  whenReady;

  data = {
    log: {
      info: true,
      modify: true,
      warn: true,
      error: true
    }
  };

  constructor () {
    this.whenReady = new Promise(resolve => {
      this.collection.onSnapshot(snapshots => {
        snapshots.forEach(doc => {
          let id = doc.id;
          let data = doc.data();
          if (!this.data[id]) { return; }
          Object.keys(data).forEach(key => {
            this.data[id][key] = data[key];
          });
        });
        resolve();
      });
    });
  }

  async getLogSettings() {
    await this.whenReady;
    return this.data.log;
  }

}

export default new Settings();
