import React from 'react';

export default () => {
  let style = {
    float: 'left',
    color: 'white',
    fontSize: '22px',
    marginRight: '20px',
    lineHeight: '46px',
    textTransform: 'uppercase',
  };
  return (
    <div style={style}>
      Freefall
    </div>
  );
};
