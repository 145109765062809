import get from 'lodash.get';

export default class User {

  doc;

  constructor (doc) {
    this.doc = doc || {};
  }

  get data() {
    return (this.doc.data) ? this.doc.data() : {};
  }

  get id() {
    return this.doc.id || '';
  }

  get name() {
    return this.data.name || '';
  }

  get email() {
    return this.data.email || '';
  }

  get permissions() {
    return this.data.permissions || {};
  }

  get contributions() {
    return this.data.contributions || 0;
  }

  can(what) {
    // Convert string into an object path: "addUsers" = "users.add"
    let path = what.split(/(?=[A-Z])/).reverse().join('.').toLowerCase();
    return get(this.permissions, path);
  }

}
