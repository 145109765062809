import micros from 'microseconds';
import getLocation from 'utils/location';
import Settings from 'services/Settings';
import Session from 'services/Session';
import db from 'services/Database';

class Logs {

  collection = db.collection('logs');

  info(...args) {
    this.log('info', ...args);
  }

  modify(...args) {
    this.log('modify', ...args);
  }

  warn(...args) {
    this.log('warn', ...args);
  }

  error(...args) {
    this.log('error', ...args);
  }

  async log(level = '', action = '', details = {}) {

    let settings = await Settings.getLogSettings();
    if (!settings[level]) { return; }

    let time = micros.now();
    let path = getLocation();
    let who = Session.user.name;
    let session = Session.id;

    let body = {
      time,
      session,
      action,
      level,
      path
    };

    if (who) { body.who = who; }
    if (Object.keys(details).length > 0) { body.details = details; }
    this.collection.doc(String(time)).set(body);
  }

}

export default new Logs();
