import React from 'react';
import {Layout} from 'antd';
import buildVersion from 'utils/buildVersion';

export default () => {

  let style = {
    textAlign: 'center',
    fontSize: '12px'
  };

  let buildStyle = {
    fontSize: '9px',
    opacity: 0.4
  };

  return (
    <Layout.Footer style={style}>
      &copy; {(new Date()).getFullYear()} Freefall Email <br/>
      <span style={buildStyle}>{buildVersion()}</span>
    </Layout.Footer>
  )
}
