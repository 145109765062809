import React from 'react';
import {Spin} from 'antd';

export default () => {

  let containerStyle = {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    lineHeight: '100%'
  };

  let spinnerStyle = {
    top: '50%',
    position: 'absolute',
    margin: '-20px -16px 0 0'
  };

  return (
    <div style={containerStyle}>
      <Spin size="large" style={spinnerStyle}/>
    </div>
  );
}

