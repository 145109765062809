import micros from 'microseconds';
import Users from 'services/Users';
import Auth from 'services/Auth';
import User from 'objects/User';
import log from 'utils/log';

class Session {

  _id;
  _user;
  _ready;

  constructor () {
    this.generateId();
    this.onChange(user => {
      this._ready = true;
      if (user) {
        this.user = user;
        log.info('authed');
      } else {
        if (this._user) {
          this.user = user;
          log.info('deauthed');
        }
        this.generateId();
      }
    });
  }

  get id() {
    return this._id;
  }

  generateId(val) {
    this._id = window.btoa(micros.now());
  }

  get user() {
    return this._user || new User();
  }

  set user(user) {
    this._user = user;
  }

  isReady() {
    return this._ready;
  }

  isLoggedIn() {
    return !!this.user.id;
  }

  onChange(fn) {
    Auth.onAuthStateChanged(auth => {
      if (!auth) { return fn(); }
      Users.getUserById(auth.uid).then(fn);
    });
  }

  start(email, pass) {
    return Auth.signInWithEmailAndPassword(email, pass).then(({user = {}} = {}) => {
      let msg = {message: 'User metadata is missing or incomplete.'};
      return new Promise((resolve, reject) => {
        if (!user.uid) {
          this.destroy();
          return reject(msg);
        }
        Users.getUserById(user.uid).then(user => {
          if (!user) {
            reject(msg);
            this.destroy();
          } else {
            resolve(user);
          }
        });
      });
    });
  }

  destroy() {
    return Auth.signOut();
  }

}

export default new Session();
