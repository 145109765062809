import React from 'react';
import {Layout, Menu} from 'antd';
import {NavLink, withRouter} from 'react-router-dom';
import Session from 'services/Session';
import HeaderLogo from 'components/layout/HeaderLogo';

function getTopLevelRoute(path) {
  return path.split('/')[1] || '';
}

class Header extends React.Component {

  selected;

  constructor (props) {
    super(props);
    this.selected = getTopLevelRoute(props.location.pathname);
  }

  shouldComponentUpdate({location}) {
    let selected = getTopLevelRoute(location.pathname);
    if (selected === this.selected) { return false; }
    this.selected = selected;
    return true;
  }

  render() {

    let RecordsLink = Session.user.can('viewRecords')
      ? <Menu.Item key="records">
          <NavLink to="/records">Records</NavLink>
        </Menu.Item>
      : '';

    let ExportLink = Session.user.can('viewExport')
      ? <Menu.Item key="export">
          <NavLink to="/export">Export</NavLink>
        </Menu.Item>
      : '';

    let UsersLink = Session.user.can('viewUsers')
      ? <Menu.Item key="users">
          <NavLink to="/users">Users</NavLink>
        </Menu.Item>
      : '';

    let headerStyle = {
      height: '46px',
      lineHeight: '46px',
      overflow: 'hidden'
    };

    let headerContainerStyle = {
      ...headerStyle,
      padding: '0 20px',
    };

    return (
      <Layout.Header style={headerContainerStyle}>
        <div className="headerLeft" style={headerStyle}>
          <HeaderLogo/>
          <Menu
            theme="dark"
            mode="horizontal"
            selectedKeys={[this.selected]}>
            {RecordsLink}
            {ExportLink}
            {UsersLink}
          </Menu>
        </div>
        <div className="headerRight" style={headerStyle}>
          <div className="currentUser"></div>
          <div className="logout">
            <NavLink to="/logout">Logout</NavLink>
          </div>
        </div>
      </Layout.Header>
    );
  }
}

export default withRouter(Header);
